h1 {
    @apply font-bold text-4xl mb-2;
  }

  h2 {
    @apply font-bold text-2xl mb-2;
  }

  h3 {
    @apply font-bold text-xl;
  }

  h4 {
    @apply font-bold text;
  }

  nav {
    @apply fixed h-full flex justify-center shadow-lg;
    background-color: #36373F;
    width: 300px;

    & ul {
      @apply flex flex-col py-8 px-10;

      & li {
        @apply list-none flex flex-col mb-5;

        &:first-of-type {
          @apply mb-10;
        }

        &:last-of-type {
          @apply mt-auto mb-0;
        }

        & a {
          @apply flex items-center;

          & span {
            @apply ml-2 font-medium;
            color: #fff;
          }
        }
      }
    }
  }

  main {
    @apply w-full min-h-screen p-10 bg-coolGray;
    margin-left: 300px;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }

  main::-webkit-scrollbar {
    display: none;
  }

  form {
    & label {
      @apply font-bold mb-1 flex items-center;

      & input[type=checkbox], & input[type=radio] {
        @apply mr-2;
      }
    }

    & button {
      @apply text-sm text-white bg-warmGray px-6 py-2 rounded shadow-sm hover:bg-gray;
    }
  }

  button {
    @apply bg-blue text-white px-4 py-2 rounded-xl text-sm;

    &.transparent {
      @apply bg-transparent text-black;
    }
  }
