.react-tabs {
    & .react-tabs__tab-list {
      @apply flex flex-row border-b border-coolGray mb-5;

      & .react-tabs__tab {
        @apply py-2 px-3 cursor-pointer;

        &.react-tabs__tab--selected {
          @apply cursor-default border-b border-gray;
        }
      }
    }
  }

  .sliding-panel-container {
    & .panel {
      @apply bg-white;
    }

    & .glass {
      background-color: #00000080;
    }

    & .panel-content {
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: scroll; 
    }

    & .panel-content::-webkit-scrollbar {
      display: none
    }
  }

  .table {
    @apply rounded-md shadow-lg text-sm overflow-hidden bg-white w-full;
  
    & .row {
      @apply grid grid-cols-12 py-4 px-4 items-center;
      border-bottom: 1px solid #efefef80;
  
      & > :first-child {
        padding-left: 10px;
      }
  
      & > :last-child {
        padding-right: 10px;
      }
  
      &.header {
        @apply text-sm font-bold py-3 items-center bg-white;
  
        & a {
          display: flex;
          text-decoration: none;
          color: #033641;
          align-items: center;
          justify-content: space-between;
  
          & .active {
            color: #033641;
          }
  
          & .arrow-asc {
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #033641;
            margin-right: 20px;
          }
  
          & .arrow-desc {
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #033641;
            margin-right: 20px;
          }
        }
      }
  
      &.item {
        @apply items-center;
      }
    }
  
    & .table__body, & .body {
      & > :last-child {
        border-bottom: 0;
      }
    }
  
    & .footer {
      @apply px-6 py-5 col-span-12 border-t border-coolGray;

      & button {
        
      }
    }
  }


form {
  & .checkbox & .radio {
    @apply form-check-input appearance-none h-4 w-4 border border-gray rounded-sm bg-white checked:bg-blue checked:border-blue focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer;
  }

  & label {
    @apply text-sm;
  }

  & .input {
    &.text, &.textarea {
      @apply form-control block w-full px-3 py-1.5
        text-base
        font-normal
        text-warmGray
        bg-white bg-clip-padding
        border border-solid border-gray
        rounded
        transition
        ease-in-out
        m-0
        focus:text-warmGray focus:bg-white focus:border-blue focus:outline-none
        placeholder:text-gray;
    }
  }

  & .button {
    @apply px-6
      py-2.5
      bg-blue
      text-white
      font-medium
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue hover:shadow-lg
      focus:bg-blue focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-darkBlue active:shadow-lg
      transition
      duration-150
      ease-in-out;
  }

  & .select {
    & input[type=text] {
      @apply border-8
        focus:ring-0;
    }
  }
}

.google-place-predictions {
  @apply hidden absolute bg-white p-5 border rounded-md mt-3;
  
  border-color: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;

  &.open {
    @apply block;
    z-index: 1000;
  }

  & > li {
    @apply p-3 border-b cursor-pointer;
    border-color: #eee;
  }

  & :last-child {
    border-bottom: 0px;
  }
}

.datePicker-input {
  @apply form-control block w-full px-3 py-1.5
    text-base
    font-normal
    text-warmGray
    bg-white bg-clip-padding
    border border-solid border-gray
    rounded
    transition
    ease-in-out
    m-0
    focus:text-warmGray focus:bg-white focus:border-blue focus:outline-none
    placeholder:text-gray;
}

.rc-time-picker {
  & input {
    @apply form-control block w-full px-3 py-1.5 h-auto
    text-base
    font-normal
    text-warmGray
    bg-white bg-clip-padding
    border border-solid border-gray
    rounded
    transition
    ease-in-out
    m-0
    focus:text-warmGray focus:bg-white focus:border-blue focus:outline-none
    placeholder:text-gray;
  }

  & a {
    @apply h-full flex items-center top-0
  }
}

.rc-time-picker-panel-narrow {
  max-width: unset !important;
}

.rc-time-picker-panel {
  z-index: 100000 !important;
}

.pill {
  @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded-full bg-blue;

  &.gray {
    @apply bg-gray text-black;
  }

  &.lightGray {
    @apply bg-coolGray text-warmGray;
  }
}

.dealTable > div {
  @apply py-4
}

.dealTable > div:not(:first-child) {
    @apply border-t border-t-coolGray; 
}
